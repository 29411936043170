// import "@fortawesome/fontawesome-free/css/all.css";

// this approach corrects the select 2 to be duplicated when clicking the back button.
$(document).on('turbolinks:before-cache', function() {
    $('.kb-search').select2('destroy');
} );

$(document).on('turbo:load', () => {
    // get previous search value to 'remember' search
    let prevSearch = window.localStorage.getItem('prevSearch')

    $('.kb-search').select2({
        minimumInputLength: 2,
        theme: 'bootstrap4',
        placeholder: 'Search for a solution',
        ajax: {
            delay: 400,
            url: '/knowledge_base/search',
            dataType: 'json',
            method: 'GET',
            data: function (params) {
                window.localStorage.setItem('prevSearch', params.term)
                return {
                    q: params.term
                };
            },
            processResults: function (data) {
                return {
                    results: $.map(data, (item) => {
                        return {
                            text: item.title,
                            id: item.content.id
                        }
                    })
                }
            },
        },
    });

    $('.kb-search').on('select2:open', () => {
        if (prevSearch.length > 2) {
            $('.select2-search__field').val(prevSearch).trigger('keyup')
        }

        $('.select2-search__field')[0].focus()
    })

    $('.kb-search').on('select2:select', (e) => {
        let articleId = e.params.data.id

        window.location = `/knowledge_base/${articleId}`
    })
})