import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert2";

export default class extends Controller {
    static targets = [ 'required', 'form', 'submitBtn' ];

    addValid(e) {
        e.target.classList.add('is-valid')

        this.validateForm()
    }

    validateForm() {
        const isValid = (field) => {
            return field.classList.contains('is-valid')
        }

        if (this.requiredTargets.every(isValid)) {
            this.submitBtnTarget.classList.remove('disabled')
            this.submitBtnTarget.disabled = false
        } else {
            this.submitBtnTarget.classList.add('disabled')
            this.submitBtnTarget.disabled = true
        }
    }

    loadingSpinner() {
      swal.fire({
          title: 'Submitting...',
          html: '<div class="d-flex align-items-center justify-content-center"><span class="mr-2"></span><div class="spinner-border" role="status"></div></div>',
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
      });
  }

    submitForm() {
        this.formTarget.submit()
        this.loadingSpinner()
    }
}