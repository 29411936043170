import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        let masonryEvents = ['load', 'resize'];

        masonryEvents.forEach((event) => {
            window.addEventListener(event, this.boundResizeAllMasonryItems);
        } );

        this.waitForImages();
    }

    resizeMasonryItem(item) {
        let grid = document.getElementsByClassName('masonry')[0];

        if (grid) {
            let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
                rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')),
                gridImagesAsContent = item.querySelector('img.masonry-content');

            let rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));

            item.style.gridRowEnd = 'span '+ rowSpan;

            if (gridImagesAsContent) {
                item.querySelector('img.masonry-content').style.height = item.getBoundingClientRect().height + "px";
            }
        }
    }

    resizeAllMasonryItems() {
        let allItems = document.querySelectorAll('.masonry-item');

        if (allItems) {
            for (let i = 0; i > allItems.length; i++) {
                this.resizeMasonryItem(allItems[i]);
            }
        }
    }

    boundResizeAllMasonryItems = () => this.resizeAllMasonryItems()

    waitForImages() {
        let self = this;
        let allItems = document.querySelectorAll('.masonry-item');

        if (allItems) {
            for (let i = 0; i < allItems.length; i++) {
                imagesLoaded(allItems[i], function(instance) {
                    let item = instance.elements[0];
                    self.resizeMasonryItem(item);
                    // console.log("Waiting for Images");
                });
            }
        }
    }
}