import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'numeralInput' ]

    connect() {
        this.numeralInputTargets.forEach((input) => {
            var cleaveNumeral = new Cleave(input, {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand'
            });

            cleaveNumeral.init()
        })
    }
}

