import {Controller} from "@hotwired/stimulus"
import swal from "sweetalert2";

export default class extends Controller {

  handleClick(event) {
    const currentPath = window.location.pathname;

    if (currentPath.includes("/request_information")) {
      event.preventDefault();
      confirmFormAbandonment();
    }

    function confirmFormAbandonment() {
      swal.fire({
        title: "Are you sure you want to leave the form?",
        text: "Once you leave, all data will be lost, and you will have to begin a new request",
        icon: "warning",
        showCancelButton: true
      })
        .then((result) => {
          if (result.isConfirmed) {
            console.log("Delete is confirmed")
            deleteRequest()
          }
        });
    }

    function deleteRequest() {
      $.ajax({
        url: '/xras_submit/requests/delete_if_form_leave',
        type: 'DELETE',
        success: function () {
          location.href = '/xras_submit/opportunities'
        }
      })
    }
  }
}