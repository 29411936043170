import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import '../jquery'

export default class extends Controller {
    static targets = ["form", "modal"]

    initialize() {
        console.log("Modal controller connected!");
    }

    launchModal() {
        let addPersonModal = $('#add-person-modal')
        addPersonModal.modal('show')
        addPersonModal.addClass("no-click")
        console.log("The modal has been opened!")
    }

    closeModal() {
        let addPersonModal = $('#add-person-modal')
        addPersonModal.modal('hide')
        addPersonModal.removeClass("no-click")
        console.log("The modal has been closed!")
    }

    submitForm() {
        let isValid = this.validateForm(this.formTarget);

        // If our form is invalid, prevent default on the event
        // so that the form is not submitted
        if (!isValid) {
            event.preventDefault();
        } else {

            let addPersonModal = $('#add-person-modal')
            let formData = $('#add-person-form')[0]
            console.log('Submitting Form...')

            $.ajax({
                url: '/xras_submit/requests/add_a_person',
                type: 'POST',
                data: new FormData(formData),
                success:function(data){
                    const user = JSON.parse(data.user)
                    const role = data.role
                    const selectUserData = {
                        text: `${user.first_name} ${user.last_name}, ${user.organization}`,
                        id: user.username,
                        organization: user.organization,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        username: user.username
                    };
                    const newOption = new Option(selectUserData.text, selectUserData.id, false, false);
                    $(`#select-${role}`).append(newOption).trigger('select2:select', [selectUserData]);
                },
                error:function(data){
                    const error_message = data['responseJSON'].error
                    console.log(error_message)
                    if (!(window.confirm(error_message))) {
                        e.preventDefault()
                    }
                },
                cache: false,
                contentType: false,
                processData: false
            });

            formData.reset()
            console.log('CLOSING MODAL!')
            addPersonModal.modal('hide')
        }
    }

    validateForm() {
        let isValid = true;

        // Tell the browser to find any required fields
        let requiredFieldSelectors = 'select:required, input:required';
        let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

        requiredFields.forEach((field) => {
            // For each required field, check to see if the value is empty
            // if so, we focus the field and set our value to false
            if (!field.disabled && !field.value.trim()) {
                field.focus();

                isValid = false;
            }
        });

        return isValid;
    }
}
