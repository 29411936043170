import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "create", "post", "addButton", "listContainer", "list", "previewButton", "notice" ]

    initialize() {
        this.postsList = this.storedPosts ? JSON.parse(this.storedPosts) : {}
    }

    connect() {
        if (this.toggled === 'true') {
            this.showToggledItems()
        } else {
            this.hideToggledItems()
        }
    }

    get storedPosts() {
        return window.localStorage.getItem('posts');
    }

    set storedPosts(value) {
        window.localStorage.setItem('posts', JSON.stringify(value))
    }

    get toggled() {
        return window.localStorage.getItem('listToggled')
    }

    set toggled(value) {
        window.localStorage.setItem('listToggled', value)
    }

    toggle() {
        if (this.toggled === 'false') {
            this.showToggledItems()
            this.toggled = 'true'
        } else {
            this.hideToggledItems()
            this.toggled = 'false'
        }
    }

     showToggledItems() {
        this.setButtonToCancel()
        this.showNotification()
        this.showAddButtons()
        this.setAddButton()
        this.setNewsletterList()
        this.showCreateNewsletter()
    }

    hideToggledItems() {
        this.setButtonToCreate()
        this.hideNotification()
        this.hideAddButton()
        this.hideCreateNewsletter()
    }

    setButtonToCancel() {
        this.createTarget.innerHTML = `
          <span class="btn-wrapper--icon">
              <i class="fas fa-ban"></i>
          </span>
          <span class="btn-wrapper--label">
              Cancel
          </span>
        `
    }

    showNotification() {
        let notice = this.noticeTarget.classList
        notice.remove('d-none')
        notice.add('show')
        notice.add('d-flex')
    }

    showAddButtons() {
        this.addButtonTargets.forEach((button) => {
            button.style.opacity = '1'
            button.disabled = false
        })
    }

    showCreateNewsletter() {
        this.listContainerTarget.style.opacity = '1'
    }

    setButtonToCreate() {
        this.createTarget.innerHTML = `
          <span class="btn-wrapper--icon">
              <i class="fas fa-plus"></i>
          </span>
          <span class="btn-wrapper--label">
              Create Newsletter
          </span>
        `
    }

    hideNotification() {
        let notice = this.noticeTarget.classList
        notice.remove('show')
        notice.remove('d-flex')
        notice.add('d-none')
    }

    hideAddButton() {
        this.addButtonTargets.forEach((button) => {
            button.style.opacity = '0'
            button.disabled = true
        })
    }

    hideCreateNewsletter() {
        this.listContainerTarget.style.opacity = '0'
    }

    addToNewsletter(e) {
        let postId = e.target.dataset.newNewsletterPostId
        let title = e.target.dataset.newNewsletterPostTitle
        this.postsList[title] = postId
        this.storedPosts = this.postsList
        this.setNewsletterList()
        this.setAddButton()
    }

    removeFromNewsletter(e) {
        delete this.postsList[e.target.dataset.newNewsletterKey]
        this.storedPosts = this.postsList
        this.setNewsletterList()
        this.setAddButton()
    }

    setAddButton() {
        this.addButtonTargets.forEach((button) => {
            if (Object.values(this.postsList).includes(button.dataset.newNewsletterPostId)) {
                button.disabled = true
                button.style.opacity = '0.7'
                button.innerHTML = `<span><i class="fas fa-check mr-2"></i> Added</span>`
            } else {
                button.disabled = false
                button.style.opacity = '1'
                button.innerHTML = `+ Add to Newsletter`
            }
        })
    }

    setNewsletterList() {
        let listItem = ''

        if (!this.postsListIsEmpty()) {
            let index = 1;

            for (const [key, value] of Object.entries(this.postsList)) {
                listItem += `
                <li class="list-group-item" id="daily-b-post-${value}" data-new-newsletter-key="${key}">
                    <div class="align-box-row d-flex justify-content-between">
                        <span>${index.toString()}. ${key}</span>
                        <button class="btn btn-icon-gray p-0" 
                                data-action="new-newsletter#removeFromNewsletter"
                                data-new-newsletter-key="${key}">
                                <i class="fas fa-times" data-new-newsletter-key="${key}"></i>
                        </button>
                    </div>
                </li>
            `
            index++
            }
        } else {
            listItem += `
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <i>Select posts to add to the newsletter</i>
                </li>
            `
        }

        this.setPreviewButton()
        this.listTarget.innerHTML = listItem
    }

    setPreviewButton() {
        if (this.postsListIsEmpty()) {
            this.previewButtonTarget.disabled = true
            this.previewButtonTarget.innerText = `Select Posts`
        } else {
            this.previewButtonTarget.disabled = false
            this.previewButtonTarget.innerText = `Preview Newsletter`
        }

    }

    postsListIsEmpty() {
        return Object.entries(this.postsList).length == 0
    }

    getPreview() {
        this.postList = JSON.stringify(this.postsList)

        let params = Object.values(this.postsList)
        window.location = `/newsletters/new?ids=${params}`
    }

    emptyList() {
        this.postsList = {}
        this.storedPosts = {}
    }
}

