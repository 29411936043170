import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['widget']

    connect() {
    }

    clicked() {
        // this.widgetTarget.classList.toggle("invisible");
        const widget = document.getElementById('jira-servicedesk-widget');
        const form = document.getElementById('jira-issue-form');
        
        console.log('JIRA BUTTON WORKING')

        if (widget.style.display === "block") {
            form.reset()
            widget.style.display = "none"
        } else {
            widget.style.display = "block"
        }
    }

    submitSuccess(e) {
        // This might look a little magical but
        // its just deconstructing the standard rails-ujs event detail
        const [_data, _status, xhr] = e.detail
        const widget = document.getElementById('jira-servicedesk-widget');
        const form = document.getElementById('jira-issue-form');
        const editor = document.getElementById('jira-textarea');

        // Console log out the status of the Jira Request
        console.log(`THE REQUEST STATUS IS: ${e.detail[2].status}`)
        console.log(`Submitted Form Successfully!`)

        editor.value = ''
        form.reset()
        widget.style.display = "none"

        this.msgAlert(e.detail[2].status)
    }

    msgAlert(status) {

        let element = document.getElementById('banner-message')

        if (status === 200) {
             element.innerHTML +=
                "<div class=\"alert d-flex align-items-center pl-2 align-content-center alert-success alert-dismissible fade show\" role=\"alert\">\n" +
                "        <span class=\"font-size-lg d-block d-40 mr-2 text-center\">\n" +
                "            <i class=\"far fa-life-ring\"></i>\n" +
                "        </span>\n" +
                "            <span>\n" +
                "            <strong class=\"d-block\">Success!</strong> Your ticket has been submitted successfully.\n" +
                "        </span>\n" +
                "            <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n" +
                "              <span aria-hidden=\"true\">×</span>\n" +
                "            </button>\n" +
                "      </div>";
        } else {
            element.innerHTML +=
                "<div class=\"alert d-flex align-items-center pl-2 align-content-center alert-danger alert-dismissible fade show\" role=\"alert\">\n" +
                "    <span class=\"font-size-lg d-block d-40 mr-2 text-center\">\n" +
                "        <i class=\"fas fa-headset\"></i>\n" +
                "    </span>\n" +
                "    <span>\n" +
                "        <strong class=\"d-block\">Danger!</strong> This is an error alert—check it out!\n" +
                "    </span>\n" +
                "    <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n" +
                "        <span aria-hidden=\"true\">×</span>\n" +
                "    </button>\n" +
                "</div>"
        }

        setTimeout(() => {
            element.style.display = 'none'
        }, 5000)
    }

}