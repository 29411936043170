import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "icon", "button"]

  collapseIcon = '<i class="fas fa-angle-down"></i>'
  expandIcon = '<i class="fas fa-angle-up"></i>'

  menuIconToggler() {
    if (this.menuOpen) {
      this.menuTarget.classList.remove('show')
      this.iconTarget.innerHTML = this.collapseIcon
    } else {
      this.menuTarget.classList.add('show')
      this.iconTarget.innerHTML = this.expandIcon
    }
  }

  get menuOpen() {
    return this.menuTarget.classList.contains('show')
  }
}