import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'info', 'textbox']

  initialize() {
    if (this.infoTarget.dataset.info !== 'null') {
      this.getPreviousCheckboxSelections()
      this.getPreviousTypedResponse()
    }
    this.collectAllAnswers()
    this.addPreviousTextbox()
    this.addPreviousCheckbox()
    this.initialSelections = this.getPreviousCheckboxSelections()
    this.initialTextbox = this.getPreviousTypedResponse()
  }

  getPreviousTypedResponse() {
    let typedResponse = ""
    let data = JSON.parse(this.infoTarget.dataset.info)
    data.forEach((field) => {
      if (field['typedAnswer']) {
        typedResponse = field['typedAnswer']
      }
    })
    return typedResponse
  }

  getPreviousCheckboxSelections() {
    const previousMultiChoice = []
    let data = JSON.parse(this.infoTarget.dataset.info)
    data.forEach((field) => {
      if (field['answerId']) {
        field['answerId'].forEach((id) => {
          previousMultiChoice.push(id)
        })
      }
    })
    return previousMultiChoice
  }

  collectAllAnswers() {
    const answersArray = []
    this.checkboxTargets.forEach((checkbox) => {
      // checkBox.checked = true;
      answersArray.push(checkbox.value)
    })
    return answersArray.map(Number)
  }

  addPreviousTextbox() {
    if (this.hasTextboxTarget) {
      this.textboxTarget.value = this.getPreviousTypedResponse()
    }
  }

  addPreviousCheckbox() {
    this.checkboxTargets.forEach((checkbox) => {
      if (this.getPreviousCheckboxSelections().includes(checkbox.value)) {
        checkbox.checked = true
      }
    })
  }

  goBackConfirmation(e) {
    this.finalSelections = [];

    //Gather final selections/responses
    for (const element of $('.checkbox:checked')) {
      this.finalSelections.push(element.getAttribute('value'));
    }

    let confirmed = "Are you sure? Moving back will delete your current selections. To save, continue to next step."

    if (
      (this.finalSelections.toString() !== this.initialSelections.toString())
      || (this.initialTextbox !== this.textboxTarget.value)
    ) {
      if (!(window.confirm(confirmed))) {
        e.preventDefault()
      }
    }
  }

}