// import "@fortawesome/fontawesome-free/css/all.css";

$(document).on('turbo:load', () => {
    // Initialize select2
    $('#impersonate-user').select2({
        minimumInputLength: 2,
        theme: 'bootstrap4',
        placeholder: 'Impersonate user...',
        ajax: {
            url: '/get_people',
            delay: 400,
            dataType: 'json',
            method: 'GET',
            data: function (params) {
                return {
                    q: params.term
                };
            },
            processResults: function (data) {
                return {
                    results: $.map(data.result, function (item) {
                        let org = item.organization != undefined ? `, ${item.organization}` : '';
                        if (!item.username.includes("-")) {
                            return {
                                text: `${item.firstName} ${item.lastName}${org}`,
                                id: item.username,
                                organization: item.organization,
                                firstName: item.firstName,
                                lastName: item.lastName,
                                username: item.username,
                                email: item.email
                            }
                        }
                    })
                }
            },
        },
    });

    $('#impersonate-user').on("select2:select", (e) => {
        let username = e.params.data.username
        let email = e.params.data.email

        fetch('/impersonate_user', {
            method: 'POST',
            mode: 'same-origin',
            credentials: "same-origin",
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({
                user: username,
                email: email,
                path: window.location.href
            })
        })
            .then(response => response.json())
            .then((data) => {
                window.location = data.redirect_url
            })
    })
})