import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'grid', 'gridItem' ]

    connect() {
        this.resizeAllGridItems()
        window.addEventListener("resize", this.resizeAllGridItems());
    }

    resizeGridItem(item){
        let grid = this.gridTarget;
        let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
        let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        let rowSpan = Math.ceil((item.querySelector('.card').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
        item.style.gridRowEnd = "span "+rowSpan;
    }

    resizeAllGridItems(){
        this.gridItemTargets.forEach((item) => {
            this.resizeGridItem(item);
        })
    }
}