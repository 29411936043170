import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert2";

export default class extends Controller {
    static targets = ['body']

    connect() {
        window.addEventListener("trix-file-accept", this.boundRestrictAttachments)
    }

    disconnect() {
        window.removeEventListener("trix-file-accept", this.boundRestrictAttachments)
    }

    boundRestrictAttachments = e => this.restrictAttachments(e)

    restrictAttachments(event) {
        event.preventDefault();
        swal.fire({
            title: "Warning",
            text: "Files cannot be attached to post body",
            buttonsStyling: !1,
            confirmButtonClass: "btn btn-primary"
        });
    }
}