// Entry point for the build script in your package.json

import "@rails/ujs"
import "@rails/actioncable"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "./jquery"
import "./src/impersonate-user"
import "./src/kb-search"
import "./src/plugins"
import "./src/select-related-personnel"
import "./controllers"
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap-drawer/dist/js/drawer.min"
import "trix"
import "select2/dist/js/select2.full.min"
import "./custom/custom";
import "tabulator-tables"
import "./custom/chart/charts.min"
import "./custom/chartkick/chartkick.min"



document.addEventListener("turbolinks:load", function() {
    if (typeof Chart !== "undefined") {
        Chartkick.configure({language: 'en'});
    }
});