import PerfectScrollbar from "perfect-scrollbar";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "container" ]

    connect() {
        const scrollable_containers = this.containerTargets

        scrollable_containers.forEach((container) => {
            const ps = new PerfectScrollbar(container, {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        });
    }
}