import "select2";

$(document).on('turbo:load', () => {
  // Hide the table initially
  $('#request-users-table').hide()
  // updateUsersTable()

  // Initialize select2
  $('.select-single').select2({
    minimumInputLength: 2,
    theme: 'bootstrap4',
    placeholder: 'Begin typing to search...',
    ajax: {
      url: '/get_people',
      dataType: 'json',
      method: 'GET',
      data: function(params) {
        return {
          q: params.term
        };
      },
      processResults: function(data) {
        return {
          results: $.map(data.result, function(item) {
            let org = item.organization != undefined ? `, ${item.organization}` : '';
            return {
              text: `${item.firstName} ${item.lastName}${org}`,
              id: item.username,
              organization: item.organization,
              firstName: item.firstName,
              lastName: item.lastName,
              username: item.username
            }
          })
        }
      },
    },
  });

  var selectedUsers;
  var userIndex = 0;

  // Give logged-in user role shortcut
  if (document.getElementById('select-current-user-modal')) {
    $('#select-current-user-modal').modal('show');
    let userInfo = JSON.parse(document.getElementById('select-current-user-modal').dataset.userInfo);

    $('.role-btn').on('click', function (e) {
      let roleInfo = JSON.parse(e.target.dataset.roleInfo);
      userInfo.username = userInfo['username']
      userInfo.role = roleInfo.roleType;
      userInfo.displayRoleType = roleInfo.name;

      selectedUsers[userIndex] = userInfo;
      userIndex++

      updateUsersTable();
      updatePersonnelForm();
      handleRoleLimits();
      $('#select-current-user-modal').modal('hide');
    })
  }

  // Handle users from session
  if (document.getElementById('request-users-table-body')) {
    selectedUsers = JSON.parse(document.getElementById('request-users-table-body').dataset.relatedPersonnel)
    userIndex = selectedUsers.length
    updateUsersTable();
    updatePersonnelForm();
    handleRoleLimits();
  } else {
    selectedUsers = {};
  }

  // Add Selection Event Listener
  $('.select-single').on('select2:select', function(e, data) {
    let selectionData;

    // Add selection to selectedUsers object
    if (data != null) {
      selectionData = data
    } else {
      selectionData = $(e.target).select2('data')[0]
    }

    selectedUsers[userIndex] = selectionData;

    // Assign role to selectedUser
    selectedUsers[userIndex]['role'] = $(e.target).attr('data-role-type')
    selectedUsers[userIndex]['displayRoleType'] = $(e.target).attr('data-role-name')

    updateUsersTable()
    updatePersonnelForm()
    handleRoleLimits()

    // Clear selection from select2
    $('.select-single option:selected').remove()
    return userIndex++
  });

  function updateUsersTable() {
    let tableRowElements = '';
    let usersTable = $('#request-users-table').show()
    let tableBody = $('#request-users-table-body')

    if (Object.keys(selectedUsers).length < 1) {
      tableBody.empty()
      tableBody.append(
        `
          <tr>
          <td colspan="5" class="text-center">
            <i>Please select fields from above.</i>
          </td>
        </tr>
        `
      )
    } else {
      // clear the user table
      tableBody.empty();

      for (const user in selectedUsers) {
        let currentUser = selectedUsers[user]
        let lastName = currentUser['lastName'] != undefined ? currentUser['lastName'] : ''
        let firstName = currentUser['firstName'] != undefined ? currentUser['firstName'] : ''
        let organization = currentUser['organization'] != undefined ? currentUser['organization'] : ''
        let username = currentUser['username'] != undefined ? currentUser['username'] : ''

        tableRowElements +=
        `
          <tr data-related-personnel-target="row" id="${username}">
            <td>${lastName}</td>
            <td>${firstName}</td>
            <td>${organization}</td>
            <td>${currentUser['displayRoleType']}</td>
            <td>
              <button class='btn btn-sm ncar-btn-secondary remove-user' data-index='${user}'>
                Remove
              </button>
            </td>
          </tr>
        `
      }

      tableBody.append(tableRowElements)
      usersTable.show()
    }
  }

  function updatePersonnelForm() {
    // Clear the form fields
    $('#add-personnel-container').empty()

    let index = 0;

    // Iterate over selectedUsers obj and add to form
    for (user in selectedUsers) {
      $('#add-personnel-container').append(`
        <input value="${selectedUsers[user]['username']}" type="hidden" name="related_personnel[${index}][username]" id="related_personnel_${index}_username">
        <input value="${selectedUsers[user]['role']}" type="hidden" name="related_personnel[${index}][roles][role]" id="related_personnel_${index}_role">
        <input value="${selectedUsers[user]['displayRoleType']}" type="hidden" name="related_personnel[${index}][roles][displayRoleType]" id="related_personnel_${index}_display_role_type">
      `);
      index++
    }
  }

  function handleRoleLimits() {
    // enable all selects, initially
    $('.select-single').each( function() {
      $(this).prop('disabled', false)
    });

    let rolesArray = [];

    // Only need to track non-user roles
    for (user in selectedUsers) {
      if (selectedUsers[user]['role'] !== 'User') {
        rolesArray.push(selectedUsers[user]['role'])
      }
    }

    rolesArray.forEach((role) => {
      idFormatedName = role.toLowerCase().replace(' ', '-')
      $(`#select-${idFormatedName}`).prop('disabled', true)
    })

    let pi_role_count = []

    rolesArray.forEach((role) => {
      if (role === 'PI') {
        pi_role_count.push(role)
      }
    })

    // Prevent user from continuing without a Project Lead
    if (rolesArray.includes('PI') && pi_role_count.length === 1) {
      $('#submit-related-personnel').prop('disabled', false)
    } else {
      $('#submit-related-personnel').prop('disabled', true)
    }

    updateAddPersonModalSelectOptions(rolesArray)
  }

  function updateAddPersonModalSelectOptions(usedRoles) {
    $('#role > option').each(function() {
      $(this).prop('disabled', false)
    })

    usedRoles.forEach((userRole) => {
      $(`#role > option[value="${userRole}"]`).prop('disabled', true)
    })
  }

  $(document).on('click', '.remove-user', function(e) {
    let index = $(e.target).attr('data-index');

    delete selectedUsers[index]
    handleRoleLimits()
    updateUsersTable()
    updatePersonnelForm()
  })

  $('#submit-related-personnel').click(function() {
    $('#add-related-personnel-form').submit()
  })

  $('.select-single').on('select2:open', () => {
    $('.select2-search__field')[0].focus()
  })
});
