$(document).on('turbo:load', () => {

    $((function () {
        $('.role-selection-btn').on('click', function() {
            $(this).prop('disabled', true);
            $('#close-select-user-modal').click();
        });
    }));

    $((function () {
        if ( $('.fa-triangle-exclamation').length ) {
            $('.confluence-information-macro>.title').css({"margin-left": "30px", "position": "absolute"})
        }
    }));

// Collapse sidebar

    $((function () {
        $('.toggle-sidebar').click((function () {
            $('body').toggleClass('sidebar-collapsed');
            $('.toggle-sidebar').toggleClass('is-active');
        }))
    }));

// Responsive menu

    $((function () {
        $('.toggle-sidebar-mobile, .sidebar-mobile-overlay').click((function () {
            $('body').toggleClass('sidebar-open-mobile');
            $('.toggle-sidebar-mobile').toggleClass('is-active');
        }))
    }));

// Responsive inner menu

    $((function () {
        $('.toggle-inner-sidebar').click((function () {
            var targetSidebar = $(this).attr('data-target');
            $(targetSidebar).toggleClass('sidebar-inner-open');
            $('.sidebar-inner-mobile-overlay').toggleClass('active');
        }))
    }));

    $((function () {
        $('.sidebar-inner-mobile-overlay').click((function () {
            $(this).removeClass('active');
            $('.app-content--sidebar').removeClass('sidebar-inner-open');
        }))
    }));

// Custom JS
    $(function () {
        $('.always_show_tool_tip')
            .attr('data-toggle', 'tooltip')
            .attr('data-placement', 'bottom')
            .tooltip({
                trigger: 'manual'
            })
            .tooltip('show');

        // Always show tooltip within a timeout
        setTimeout(function () {
            $('#saveButton1').removeClass('always_show_tool_tip').tooltip('hide');

        }, 5000);

        $('[data-toggle="tooltip"]').tooltip();

        $(document).on('click', '#open-user-prefs-btn', (e) => {
            e.preventDefault();
            $('#userPrefsDrawer').drawer('show')
        })

        $(document).on('click', '#close-user-prefs-btn', (e) => {
            e.preventDefault();
            $('#userPrefsDrawer').drawer('hide')
        })

        $(document).on('show.bs.dropdown', '#resources-dropdown', () => {
            $('#dropdown-icon').addClass('fa-rotate-180')
        })

        $(document).on('hide.bs.dropdown', '#resources-dropdown', () => {
            $('#dropdown-icon').removeClass('fa-rotate-180')
        })

        // Confluence Documentation
        // When CSS classes output 2 columns side-by-side
        if ($('.columnLayout').hasClass('two-equal')) {
            var equalColumns = '.two-equal'

            $(equalColumns).addClass('row');
            $(equalColumns).find('> div.normal').addClass('col');
        }

        // Force close modal
        $('#continue-guest-btn').click(function() {
            $('.bs-modal').modal('hide');
            console.log('Closed modal');
        });

        // Notify XRAS Submit Beta Message
        // if ($('#xras-submit').length > 0) {
        //     setTimeout(function(){
        //         $.notify({
        //             icon: 'fas fa-info',
        //             title: 'Welcome to XRAS Submit portal',
        //             message: 'We are still in BETA so if you notice any issues, please revert to our other submit portal.',
        //             url: ""
        //         }, {
        //             element: "body",
        //             type: 'second',
        //             newest_on_top: true,
        //             showProgressbar: true,
        //             allow_dismiss: true,
        //             placement: {from: 'top', align: 'center'},
        //             offset: {x: 15, y: 15},
        //             spacing: 10,
        //             z_index: 1080,
        //             delay: 1500,
        //             timer: 2500,
        //             url_target: "_blank",
        //             mouse_over: !1,
        //             animate: {enter: 'animated fadeInDown', exit: 'animated fadeOutUp'},
        //             template:
        //                 '<div data-notify="container" class="alert alert-dismissible text-white-50 shadow-sm alert-notify" role="alert">\n' +
        //                 '    <div class="alert-wrapper-bg bg-{0}"></div>\n' +
        //                 '    <div class="alert-content-wrapper">\n' +
        //                 '        <span class="alert-icon text-white" data-notify="icon"></span>\n' +
        //                 '        <div class="pl-3">\n' +
        //                 '            <span class="alert-title text-white" data-notify="title">{1}</span>\n' +
        //                 '            <div data-notify="message" class="alert-text">{2}</div>\n' +
        //                 '        </div>\n' +
        //                 '    </div>\n' +
        //                 '    <button type="button" class="close" data-notify="dismiss" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n' +
        //                 '</div>'
        //         })
        //
        //     }, 1000);
        // }
    });


// Tooltip
    $(function () {
        // $('[data-tooltip="true"]').tooltip();

        // var kbObj = document.getElementById('kb-body')
        // var secondaryHeaders = kbObj.querySelectorAll('h2');
        // var smallerHeaders = kbObj.querySelectorAll('h3');
        //
        // for (var i = 0; i < secondaryHeaders.length; i++) {
        //     var currentSecondaryHeader = secondaryHeaders[i];
        //
        //     currentSecondaryHeader.style.fontSize = '1.4rem';
        //     currentSecondaryHeader.style.opacity = '0.7';
        // }
        //
        // for (var i = 0; i < smallerHeaders.length; i++) {
        //     var currentSmallerHeader = smallerHeaders[i];
        //
        //     currentSmallerHeader.style.fontSize = '1.2rem';
        //     currentSmallerHeader.style.opacity = '0.9';
        // }
    });
});

// TODO: JIRA API IS FLACKY FOR THE MOMENT
// $(document).on('turbo:load', () => {
//     const form = document.getElementById('jira-issue-form');
//     const editor = document.getElementById('jira-textarea');
//     const attachments = $('#jira-upload-container')
//     editor.value = '';
//     form.reset();
//
//     const description = $("#form-description");
//
//     if (description.find('trix-toolbar').length > 0 && description.find('trix-editor').length > 0) {
//         description.find('trix-toolbar').hide();
//         description.find('trix-editor').hide();
//     }
//
//     if (attachments.length > 0) {
//         attachments.hide();
//     }
//
//     $('#close-widget-form').on('click', function () {
//         console.log('You have closed the widget.')
//         const widget = document.getElementById('jira-servicedesk-widget');
//         const form = document.getElementById('jira-issue-form');
//         const editor = document.getElementById('jira-textarea');
//         const title = $("#form-summary");
//         const description = $("#form-description");
//
//         editor.value = '';
//         form.reset();
//
//         if (description.find('trix-toolbar').length > 0 && description.find('trix-editor').length > 0) {
//             description.find("label").remove();
//             description.find("br").remove();
//             description.find('trix-toolbar').hide();
//             description.find('trix-editor').hide();
//         }
//
//         if (title.find("input").length > 0) {
//             title.find("label").remove();
//             title.find("br").remove();
//             title.find("input").remove();
//         }
//
//         if (attachments.length > 0) {
//             attachments.hide();
//         }
//
//         widget.style.display = "none";
//     });
//
//     $('#request_type').on('change', function () {
//         const title = $("#form-summary");
//         const description = $("#form-description");
//
//         if (description.find('trix-toolbar').length > 0 && description.find('trix-editor').length > 0) {
//             description.find("label").remove();
//             description.find("br").remove();
//             description.find('trix-toolbar').hide();
//             description.find('trix-editor').hide();
//         }
//
//         if (title.find("input").length > 0) {
//             title.find("label").remove();
//             title.find("br").remove();
//             title.find("input").remove();
//         }
//
//         if (attachments.length > 0) {
//             attachments.hide();
//         }
//
//         $('#jira-loading-fields').show();
//
//         const request_type_id = $('option:selected', this).val();
//
//         $.ajax({
//             url: `/request_type_fields/${request_type_id}`,
//             success: function (response) {
//                 $('#jira-loading-fields').hide();
//
//                 $('#jira-issue-form').prepend(
//                     `<input type="hidden" id="request_type_id" name="request[type_id]" value="${request_type_id}" />`
//                 );
//
//                 const fields = response.requestTypeFields
//
//                 description.find('trix-toolbar').show();
//                 description.find('trix-editor').show();
//
//                 $.each(fields, function (key, value) {
//
//                     if (value.fieldId === 'summary' && value.jiraSchema.type === 'string') {
//                         $("#form-summary").append(
//                             `<label for="summary">${value.name}</label>
//                         <br>
//                         <input length="maximum 255" type="text" name="summary" id="summary" ${value.required ? 'required' : null}>`
//                         );
//                     }
//
//                     if (value.fieldId === 'description' && value.jiraSchema.type === 'string') {
//                         $("#form-description").prepend(
//                             `<br><label for="description">${value.name} </label><br>`
//                         );
//
//                         if (value.required === true) {
//                             $("#jira-textarea").prop('required', true);
//                         }
//                     }
//
//                 })
//
//                 $("#trix-toolbar-1").css({"display": "block !important"});
//                 $("#jira-textarea").css({"display": "block !important"});
//
//                 attachments.show();
//             }
//         })
//     });
// });
