import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    initialize() {
        this.initialSelections = this.gatherSelections()
    }

    gatherSelections() {
        const selections = [];

        const form = $('.form-control').serializeArray();

        for (const element of form) {
            selections.push(element['value']);
        }
        return this.filterSelections(selections)
    }

    filterSelections(selections) {
        return selections.filter(function (el) {
            return (el !== null) && (el !== "");
        });
    }

    goBackConfirmation(e) {
        this.finalSelections = this.gatherSelections()

        let confirmed = "Are you sure? Moving back will delete your current selections. To save, continue to next step."

        if (this.initialSelections.toString() !== this.finalSelections.toString()) {
            if (!(window.confirm(confirmed))) {
                e.preventDefault()
            }
        }
    }
}