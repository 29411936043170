import { Controller } from "@hotwired/stimulus"
import Tabulator from "tabulator-tables";

export default class extends Controller {
    static targets = ["projectUsageTable"];
    static values = { projectCode: String }

    connect() {
        this.buildProjectUsageTable()
    }

    buildProjectUsageTable() {
        let i = 1;
        let self = this;

        new Tabulator(this.projectUsageTableTarget, {
            ajaxURL: `/sam/project_usage_report?project_code=${this.projectCodeValue}`,

            ajaxResponse: function(url, params, response) {
                if (response.length === 0) {
                    self.displayNoResultsMessage();
                    return []; // return an empty array to Tabulator
                }
                return response; // return the response back to Tabulator
            },

            layout: 'fitDataStretch',
            virtualDom: false,
            responsiveLayout: true,
            columns: [
                { title: 'Resource', field: 'resource_name', align:'left' },
                { title: 'Status', field: 'status', align:'left' },
                { title: 'Start', field: 'start', align:'left' },
                { title: 'End', field: 'end', align:'left' },
                { title: 'Allocation', field: 'allocation', align:'left' },
                { title: 'Usage', field: 'usage', align:'left' },
                { title: 'Adjustments', field: 'adjustments', align:'left' },
                { title: 'Balance', field: 'balance', align:'left' }
            ],
            initialSort: [
                {
                  column: 'status',
                  dir: 'desc'  // Sort based on status
              }
            ],
            rowFormatter: function(row) {
                if ((row.getData().threshold_reports) && (!(row.getData().status === 'Expired'))) {
                    // row._row.element.style.cursor = 'pointer'
                    row._row.element.firstElementChild.innerHTML = '<i class="fa fa-square-plus mr-2" title="Click for more info" style="font-size: larger"></i>' + row._row.element.firstElementChild.innerHTML

                    row._row.element.addEventListener( 'click', () => {
                        const subTable = row._row.element.lastElementChild
                        const rowElement = row.getElement();
                        const icon = rowElement.querySelector(".svg-inline--fa");

                        if (icon) {
                            // Toggle the icon class
                            if (icon.classList.contains("fa-square-minus")) {
                                icon.classList.remove("fa-square-minus");
                                icon.classList.add("fa-square-plus");
                            } else {
                                icon.classList.remove("fa-square-plus");
                                icon.classList.add("fa-square-minus");
                            }
                        }

                        if (subTable.style.display === 'block') {
                            subTable.style.display = 'none'
                        } else {
                            subTable.style.display = 'block'
                        }
                    });

                    //create and style holder elements
                    var holderEl = document.createElement("div");
                    var tableEl = document.createElement("div");

                    holderEl.style.display = 'none'
                    holderEl.style.boxSizing = "border-box";
                    holderEl.style.padding = "10px 30px 10px 10px";
                    holderEl.style.borderTop = "1px solid #333";
                    holderEl.style.borderBotom = "1px solid #333";
                    holderEl.style.background = "#ddd";
                    holderEl.setAttribute('id', `sub-table-${i}`)

                    tableEl.style.border = "1px solid #333";

                    holderEl.appendChild(tableEl);
                    row.getElement().appendChild(holderEl);

                  // Prevent clicks within the sub-table from collapsing it
                  tableEl.addEventListener('click', (e) => {
                    e.stopPropagation();
                  });

                    new Tabulator(tableEl, {
                        layout:"fitColumns",
                        data:row.getData().threshold_reports,
                        columns: [
                            { title: 'Period', field: 'label', align:'center' },
                            { title: 'Allocation', field: 'allocationAmount', align:'center' },
                            { title: 'Charges', field: 'charges', align:'center' },
                            { title: 'Percent Usage', field: 'percentUsage', align:'center' },
                        ]
                    })

                    i++
                } else {
                  row._row.element.style.cursor = 'auto';
                }

                const hasSubTable = row._row.element.lastElementChild.id.includes('sub-table')

                if (hasSubTable) {
                    const subTable = row._row.element.lastElementChild
                }
            }
        })
    }

    displayNoResultsMessage() {
        const noResultsMessage = document.createElement('div');
        noResultsMessage.textContent = "No Usage Info Available";
        noResultsMessage.classList.add('no-results-message');

        this.projectUsageTableTarget.innerHTML = '';
        this.projectUsageTableTarget.appendChild(noResultsMessage);
    }
}