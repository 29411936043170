import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "switch", "notification", "source", "form", "input", "body", "select", "image", "button" ]

    // Store the input value temporarily for availability
    temp_value;
    temp_collection_address;

    connect() {
        this.model        = this.data.get("model")       || "model"
        this.class        = this.data.get("input-class") || "input"
        this.input_type   = this.data.get("input-type")  || "input"

        this.switchTarget.checked = false
        this.preventEdit = false
    }

    enterListener(e) {
        if (e.key === 'Enter') {
            e.target.click()
        }
    }

    toggleEditNotification() {
        if (this.switchTarget.checked) {
            this.notificationTarget.classList.remove('d-none')
            this.notificationTarget.classList.add('show')
            this.notificationTarget.classList.add('d-flex')

            this.buttonTargets.forEach((button) => { button.classList.remove('d-none') })

            this.addEditableClass()
        } else {
            this.notificationTarget.classList.remove('show')
            this.notificationTarget.classList.remove('d-flex')
            this.notificationTarget.classList.add('d-none')

            this.buttonTargets.forEach((button) => { button.classList.add('d-none') })

            this.removeEditableClass()
        }
    }

    addEditableClass() {
        this.sourceTargets.forEach((input) => {
            input.classList.add('editable')
        })
        this.bodyTarget.classList.add('editable')
        this.imageTarget.classList.add('editable')
    }

    removeEditableClass() {
        this.sourceTargets.forEach((input) => {
            input.classList.remove('editable')
        })
        this.bodyTarget.classList.remove('editable')
        this.imageTarget.classList.remove('editable')
    }

    edit(e) {
        if (this.switchTarget.checked && !this.preventEdit) {
            let inputType = e.target.dataset.inlineEditInputType
            let name = e.target.dataset.inlineEditName
            let value = e.target.textContent.trim()
            let collectionAddress = e.target.dataset.inlineEditCollection

            e.target.innerHTML = this.form({
                inputType: inputType,
                name: name,
                value: value,
                collectionAddress: collectionAddress
            })

            this.handleInputTypes()
        }
    }

    handleInputTypes() {
        if (this.hasInputTarget) {
            this.inputTarget.focus()
        }

        if (this.hasTextareaTarget) {
            this.setTextArea()
            this.textareaTarget.focus()
        }

        if (this.hasSelectTarget) {
            this.preventEdit = true
            this.setSelectOptions()
            this.selectTarget.focus()
        }
    }

    setTextArea() {
        if (this.hasTextareaTarget) {
            this.textareaTarget ? this.textareaTarget.innerHTML = this.temp_value : ''
        }
    }

    async setSelectOptions() {
        let options = await this.getOptions()
        this.hasSelectTarget ? this.selectTarget.innerHTML = options : ''
    }

    async getOptions() {
        let options = await fetch(this.temp_collection_address)
            .then(response => response.json())

        let optionsHTML = ''

        for (const [key] of Object.entries(options)) {
            optionsHTML += `<option value="${key}">${key}</option>`
        }
        return optionsHTML
    }

    submit() {
        this.formTarget.submit()
    }

    form({
             inputType = 'input',
             name = 'name',
             value = '',
             collectionAddress = '' }) {
        // Store the input value temporarily for availability
        this.temp_value = value
        this.temp_collection_address = collectionAddress
        return `
                  <form action="${this.post_url}" 
                        accept-charset="UTF-8" 
                        data-remote="true" 
                        data-target="inline-edit.form" 
                        method="post"
                    >
                    <input name="utf8" type="hidden" value="✓">
                    <input type="hidden" name="_method" value="patch">
                    <input type="hidden" name="authenticity_token" value="${this.authenticity_token}">
                    <${inputType} 
                           value="${ inputType == 'input' ? value : '' }"
                           ${ inputType == 'textarea' ? 'rows="5"' : '' }
                           name="${this.model}[${name}]"
                           class="form-control" 
                           id="${this.model}_${name}" 
                           data-target="inline-edit.${inputType}" 
                           data-action="blur->inline-edit#submit change->inline-edit#submit"
                   ></${inputType}>
                  </form>
               `
    }

    get post_url() {
        return window.location.pathname
    }

    get authenticity_token() {
        return document.querySelector("meta[name='csrf-token']").getAttribute("content");
    }

    allowModals() {
      if (this.switchTarget.checked) {
        this.bodyTarget.firstElementChild.dataset["toggle"] = "modal"
        this.imageTarget.firstElementChild.dataset["toggle"] = "modal"
      } else {
        this.bodyTarget.firstElementChild.dataset["toggle"] = ""
        this.imageTarget.firstElementChild.dataset["toggle"] = ""
      }
    }
}
