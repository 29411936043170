import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'info', 'input', 'body', 'checkbox', 'row', 'submit' ]

  initialize() {
    this.inputTarget.firstChild.disabled = true

    if (this.infoTarget.dataset.info !== 'null') {
      this.getPreviousSelections()
    }
    this.updateTable()
  }

  connect() {
    this.initialSelections = [];
    for (const element of this.rowTargets) {
      this.initialSelections.push(element.getAttribute('data-row-id'))
    }
  }

  getPreviousSelections() {
    let data = JSON.parse(this.infoTarget.dataset.info)

    data.forEach((field) => {
      this.selectedFields.push({
        'id': field.id,
        'name': field.name,
        'is_checked': field.is_primary === 'true' ? 'checked' : null
      })
    })
    this.removeOptionsForSelect()
  }

  selectedFields = []

  addField() {
    let input = this.inputTarget
    let selectedOption = input.options[input.selectedIndex]
    let checked = this.selectedFields.length < 1 ? 'checked' : null

    this.selectedFields.push({
      'id': selectedOption.value,
      'name': selectedOption.text,
      'is_checked': checked
    })

    this.updateTable()
    this.inputTarget.firstChild.selected = true
  }

  deleteField(e) {
    e.preventDefault();
    let index = event.target.dataset.fieldIndex
    this.selectedFields.splice(parseInt(index), 1)
    
    this.updateTable()
  }

  updateTable() {
    this.bodyTarget.innerHTML = '';

    let index = 0

    if (this.selectedFields.length > 0) {
      this.selectedFields.forEach((field) => {
        this.bodyTarget.innerHTML += `
          <tr data-fields-of-science-target="row" data-row-id="${field['id']}">
            <td>${field['name']}<input type="hidden" name="fos[${index}][id]" value="${field['id']}"></td>
            <td class="text-center">
              <div class="custom-checkbox custom-control">
                <input type="checkbox" 
                       data-target='fields-of-science.checkbox' 
                       data-action="fields-of-science#toggleCheckboxes" 
                       data-checkbox-index="${index}"
                       name="fos[${index}][is_primary]" 
                       value='true' 
                       class="custom-control-input" 
                       id="fos-${index}-primary"
                       ${field['is_checked']} >
                <label class="custom-control-label" for="fos-${index}-primary">Primary</label>
              </div>
            </td>
            <td class="text-center">
              <button class="remove-field btn ncar-btn-secondary btn-sm" 
              data-action="fields-of-science#deleteField" 
              data-field-index="${index}">Remove</button>
            </td>
          </tr>
        `
        index++
      })    
    } else {
      this.bodyTarget.innerHTML = `
        <tr>
          <td colspan="3" class="text-center"><i>Please select fields from above</i></td>
        </tr>
      `
    }

    this.handleContinueButton()
    this.addOptionsForSelect()
    this.removeOptionsForSelect()
  }

  toggleCheckboxes(e) {
    e.preventDefault();

    // Store the initial value
    let isChecked = e.target.checked

    // Force only 1 checkbox checked
    this.checkboxTargets.forEach((box) => {
      box.checked = false;
      delete this.selectedFields[box.dataset.checkboxIndex]['is_checked']
    })
   
    // allow checkbox toggle
    e.target.checked = isChecked;

    // set the is_checked field on the object
    if (isChecked) { 
      this.selectedFields[e.target.dataset.checkboxIndex]['is_checked'] = 'checked'
    } 

    this.handleContinueButton()
  }

  handleContinueButton() {
    let checked = [];

    this.selectedFields.forEach((field) => {
      if (field['is_checked'] === 'checked') {
        checked.push(field['is_checked'])
      }
    })

    this.submitTarget.disabled = !(checked.length > 0)
  }

  removeOptionsForSelect() {
    const options = document.getElementsByTagName("option")

    for (let field of this.selectedFields) {
      for (let item of options) {
        if (field['id'] === item.value) {
          item.style.display = "none";
        }
      }
    }
}

  addOptionsForSelect() {
    const options = document.getElementsByTagName("option")

    if (this.selectedFields.length < 1) {
        for (let item of options) {
          item.style.display = "block";
      }
    }

    for (let field of this.selectedFields) {
      for (let item of options) {
        if (field['id'] !== item.value) {
          item.style.display = "block";
        }
      }
    }

  }

  goBackConfirmation(e){
    let confirmed = "Are you sure? Moving back will delete your current selections. To save, continue to next step."

    this.finalSelections = [];

    for (const element of this.selectedFields) {
      this.finalSelections.push(element['id']).toString()
    }

    if ((this.finalSelections.toString() !== this.initialSelections.toString())) {
      if (!(window.confirm(confirmed))) {
        e.preventDefault()
      }
    }
  }

  submitForm() {
    document.getElementById('fos-form').submit()
  }
}
