import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['body', 'section']

    initialize() {
        this.expandFirstSection()
    }

    expandFirstSection() {
        let firstSection = this.sectionTargets[0]

        firstSection.children[0].children[0].classList.remove('collapsed')
        firstSection.children[1].classList.add('show')
    }
}