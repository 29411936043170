import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'row', 'info' ]

    initialize() {
      this.initialPersonnel = this.gatherPersonnel()
    }

    gatherPersonnel() {
      const personnel = [];
      const selectedUsers = JSON.parse(document.getElementById('request-users-table-body').dataset.relatedPersonnel);

      for (const element of selectedUsers) {
        personnel.push(element['username'])
      }
      return personnel
    }

    goBackConfirmation(e) {
      this.finalPersonnel = [];

      for (const element of this.rowTargets) {
        this.finalPersonnel.push(element.getAttribute('id'))
      }

      let confirmed = "Are you sure? Moving back will delete your current selections. To save, continue to next step."

      if ((this.finalPersonnel.toString() !== this.initialPersonnel.toString())) {
        if (!(window.confirm(confirmed))) {
          e.preventDefault()
        }
      }
    }
}
