import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert2";

export default class extends Controller {
    static targets = [
        'inputGroup',
        'alert',
        'agency',
        'grantNumber',
        'title',
        'piName',
        'startDate',
        'endDate',
        'awardedAmount',
        'poName',
        'poEmail'
    ];

    connect() {
        this.checkNsfSelected()
    }

    checkNsfSelected() {
        this.nsfSelected = this.agencyTarget.value == 1

        if (this.agencyTarget.value == 1) {
            this.hideGrantHint()
        } else {
            this.showGrantHint()
        }
    }

    showGrantHint() {
        this.alertTarget.classList.remove('d-none')
        this.alertTarget.classList.add('d-flex', 'show')
    }

    hideGrantHint() {
        this.alertTarget.classList.add('d-none')
        this.alertTarget.classList.remove('d-flex', 'show')
    }

    fetchGrantData() {
        if (this.nsfSelected == 'true') {
            let grantNumber = this.grantNumberTarget.value
            this.showSpinner()
            fetch(`/xras_submit/requests/nsf_award?grant=${grantNumber}`, {
            })
                .then(response => response.json())
                .then(data => this.fillGrantForm(data[0]))
                .catch(error => {
                    this.hideSpinner()
                    console.log(error)
                    swal.fire({
                        text: "Please enter a valid grant number",
                        icon: "warning"
                    });
                })
        } else {
            this.showGrantHint()
        }
    }

    fillGrantForm(data) {
        this.hideSpinner()
        this.titleTarget.value = data.title || ''
        this.piNameTarget.value = data.pdPIName || ''
        this.startDateTarget.value = data.startDate || ''
        this.endDateTarget.value = data.expDate || ''
        this.awardedAmountTarget.value = data.fundsObligatedAmt || ''
        // this.poNameTarget.value = data.poName || ''
        this.poEmailTarget.value = data.poEmail || ''
    }

    showSpinner() {
        this.inputGroupTarget.innerHTML = '<span class="spinner-border text-ncar-green loading-spinner mx-auto" role="status" id="loading-spinner"><span class="sr-only">Loading...</span></span>'
    }

    hideSpinner() {
        this.inputGroupTarget.innerHTML = 'Look up NSF grant info'
    }

    get nsfSelected() {
        return this.data.get('nsfSelected')
    }

    set nsfSelected(value) {
        this.data.set('nsfSelected', value)
    }
}
