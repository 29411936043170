import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['removeButton', 'addButton'];

  initialize() {
    this.initialSelections = this.gatherSelections();
  }

  gatherSelections() {
    const selections = [];

    const form = $('.form-control').serializeArray();

    for (const element of form) {
      selections.push(element['value']);
    }
    return this.filterSelections(selections)
  }

  filterSelections(selections) {
    return selections.filter(function (el) {
      return (el !== null) && (el !== "");
    });
  }

  addGrantTab(e) {
    e.preventDefault()

    const grantIndex = parseInt($('.grant-tab').last().attr('data-grant-index')) + 1;
    const grantDisplayNumber = grantIndex + 1;

    $('.nav-link, .tab-pane').removeClass('active')

    $('#add-grant-button').before(`
    <li class="nav-item grant-tab" id="grant-${grantDisplayNumber}-tab" data-grant-index="${grantIndex}">
    <a class="nav-link active" data-toggle="tab" href="#grant-${grantDisplayNumber}" role="tab" aria-controls="grant-${grantDisplayNumber}" aria-selected="true">Grant #${grantDisplayNumber}
      <span class="remove-grant btn p-0 ml-3" data-grant-index=${grantDisplayNumber}>
        <i class='far fa-trash-alt' data-grant-index=${grantDisplayNumber} data-target="supporting-grants.removeButton" data-action="click->supporting-grants#removeGrant"></i>
         <i class="fas fa-spinner fa-spin" id="loading-spinner-${grantDisplayNumber}"></i>
      </span>
    </a>
    </li>
    `)

    this.addTabContent(grantDisplayNumber)

    /*
    Add timeout to wait until form is fully loaded,
    otherwise DOM elements are not accessible for manipulation
    */
    setTimeout(() => {
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} input`, 'name', grantIndex)
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} input`, 'id', grantIndex)
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} select`, 'name', grantIndex)
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} select`, 'id', grantIndex)
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} .custom-checkbox label`, 'for', grantIndex)
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} textarea`, 'name', grantIndex)
      this.updateElementIdentifier(`#grant-${grantDisplayNumber} textarea`, 'id', grantIndex)
    }, 4000)
  }

  addTabContent(grantDisplayNumber) {
    $('.tab-content').append(`
  <div id="grant-${grantDisplayNumber}" class="tab-pane active" role="tabpanel" aria-labelledby="grant-${grantDisplayNumber}-tab"></div>
`)
    this.getForm(grantDisplayNumber)
  }

  getForm(grantDisplayNumber) {
    fetch('/xras_submit/requests/add_grant?opportunity_id=#{params[:opportunity_id]}')
      .then((response => response.text()))
      .then(html => {
        const form = document
          .createRange()
          .createContextualFragment(html);
        document.getElementById(`grant-${grantDisplayNumber}`).appendChild(form);
        this.removeLoadingSpinner(grantDisplayNumber)
      })
  }

  updateElementIdentifier(selector, property, grantIndex) {
    $(selector).each(function () {
      let prevProperty = $(this).prop(property);
      let newProperty = prevProperty.replace(/[0-9]/g, grantIndex)
      console.log(grantIndex)
      $(this).prop(property, newProperty)
    })
  }

  removeLoadingSpinner(grantDisplayNumber) {
      document.getElementById(`loading-spinner-${grantDisplayNumber}`).style.display = "none";
  }

  removeGrant(e) {
    const grantToRemove = e.target.dataset.grantIndex

    if (this.tabIsActive(grantToRemove)) {
      // remove grants from DOM
      document.getElementById(`grant-${grantToRemove}-tab`).outerHTML = '';
      document.getElementById(`grant-${grantToRemove}`).outerHTML = '';

      // focus on preceding tab
      document.getElementById(`grant-${grantToRemove - 1}-tab`).children[0].classList.add('active', 'show');
      document.getElementById(`grant-${grantToRemove - 1}`).classList.add('active', 'show');
    }
  }

  tabIsActive(grantIndex) {
    return document.getElementById(`grant-${grantIndex}-tab`).children[0].classList.contains('active')
  }

  submitForm() {
    document.getElementById('supporting-grants-form').submit()
  }

  goBackConfirmation(e) {
    this.finalSelections = this.gatherSelections();

    let confirmed = "Are you sure? Moving back will delete your current selections. To save, continue to next step."

    if (this.initialSelections.toString() !== this.finalSelections.toString()) {
      if (!(window.confirm(confirmed))) {
        e.preventDefault()
      }
    }
  }

}
