import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert2";

export default class extends Controller {
    static targets = [
        "container",
        "allocations",
        "incompleteCount",
        "submittedCount",
        "underReviewCount",
        "approvedCount",
        "rejectedCount",
    ];

    connect() {
        const xrasContainer = document.getElementById('xras-user-container')
        document.body.contains(xrasContainer) ? this.getRequests() : null

        // When hitting the browser back button, force page reload so duplicate requests are not shown
        window.onpopstate = function() {
            location.reload();
        }
    }

    getRequests() {
        const self = this;

        fetch(`/xras_submit/requests_count`)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            })
            .then(data => {
                this.displayStatusCount(data['requests_count'])
                if (data['opportunities'].length === 0) {
                    self.noRequestsFound()
                }
                data['opportunities'].forEach(opportunity => {
                    self.getRequestCards(opportunity)
                })
            })
            .catch(error => {
                console.log(error)
                this.renderCountErrors();
                this.renderCardErrors();
            })
    }

    displayStatusCount(data) {
        this.incompleteCountTarget.innerHTML = data['incomplete']
        this.submittedCountTarget.innerHTML = data['submitted']
        this.underReviewCountTarget.innerHTML = data['under_review']
        this.approvedCountTarget.innerHTML = data['approved']
        this.rejectedCountTarget.innerHTML = data['rejected']
    }

    getRequestCards(opportunity) {
        fetch(`/xras_submit/requests?opportunity_id=${opportunity.opportunity_id}&opportunity_name=${opportunity.opportunity_name}`)
            .then(response => response.text())
            .then(html => {
                let loadingSpinner = document.getElementById('loading-allocations')

                if (loadingSpinner) {
                    loadingSpinner.remove()
                }

                let newDiv = document.createElement('div')
                newDiv.innerHTML = html;
                this.allocationsTarget.appendChild(newDiv)
            })
            .catch(error => {
                console.log(error)
                this.renderCardErrors()
            })
    }

    renderCountErrors() {
        const countTargets = [
            this.incompleteCountTarget,
            this.submittedCountTarget,
            this.underReviewCountTarget,
            this.approvedCountTarget,
            this.rejectedCountTarget
        ]

        countTargets.forEach((count) => {
            count.innerHTML = 'N/A'
        })
    }

    noRequestsFound() {
        const loadingSpinner = document.getElementById('loading-allocations')

        if (loadingSpinner) {
            loadingSpinner.remove()
        }

        const newDiv = document.createElement('div')
        newDiv.innerHTML = `<h2 class="text-center py-3">No Requests Found</h2>`

        this.allocationsTarget.appendChild(newDiv)
    }

    renderCardErrors() {
        const loadingSpinner = document.getElementById('loading-allocations')

        if (loadingSpinner) {
            loadingSpinner.remove()
        }

        const newDiv = document.createElement('div')
        newDiv.innerHTML = `<h2 class="text-center py-3">Error Retrieving Requests</h2>`

        this.allocationsTarget.appendChild(newDiv)
    }
}