import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'notice' ]

    toggleNotification() {
        let notificationClassList = this.noticeTarget.classList

        if (notificationClassList.contains('d-none')) {
            notificationClassList.remove('d-none')
            notificationClassList.add('show')
            notificationClassList.add('d-flex')
        } else {
            notificationClassList.remove('show')
            notificationClassList.remove('d-flex')
            notificationClassList.add('d-none')
        }
    }
}