import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert2";

export default class extends  Controller {
    static targets = [ 'info' ]

    connect() {
        document.addEventListener('turbolinks:before-visit', this.boundShowAlert)
        // this.inactivityTime()
    }

    disconnect() {
        document.removeEventListener('turbolinks:before-visit', this.boundShowAlert)
    }

    // Bind this for removal of event listener
    boundShowAlert = e => this.showAlert(e)

    showAlert(e) {
        e.stopPropagation()
        let targetUrl = e['data']['url']

        if (this.loggingIn(e)) {
        //    don't do anything if user is logging in
        } else if (this.leavingWizard(e)) {
            e.preventDefault();
            swal.fire({
                title: "Are you sure you want to leave the form?",
                text: "Once you leave, all data will be lost",
                icon: "warning",
                showCancelButton: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location = targetUrl
                    } else {
                        e.preventDefault()
                    }
                });
        }
    }

    leavingWizard(e) {
        let currentController = this.infoTarget.dataset.controllerInfo
        return !e['data']['url'].includes(currentController)
    }

    loggingIn(e) {
        return e.data.url.includes('sign_in')
    }

    inactivityTime() {
        let time;
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;

        function sessionTimeout() {
            swal.fire({
                title: "Are you still there?",
                text: "In 3 minutes your time will expire. All data will be lost unless you continue to complete your request.",
                confirmButtonText: "Continue Request",
                timer: 180000,
                icon: "question",
                allowOutsideClick: false
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.href
                    } else {
                        $.ajax({
                            url: '/xras_submit/requests/delete_if_inactive',
                            type: 'DELETE',
                            success: function () {
                                console.log("Session has been deleted.");
                                location.href = '/xras_submit/opportunities'
                            },
                            ajaxError: function (xhr, textStatus, errorThrown) {
                                console.log(xhr)
                                console.log(textStatus)
                                console.log(errorThrown)
                            }
                        })
                    }
                });
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(sessionTimeout, 300000)
            // 1000 milliseconds = 1 second
        }
    };

}