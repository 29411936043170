import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu", "icon", "button"]

    menuIcon = '<i class="fas fa-bars"></i>'
    closeIcon = '<i class="fas fa-times"></i>'

    toggleMenuIcon() {
        if (this.menuOpen) {
            this.menuTarget.classList.remove('show')
            this.iconTarget.innerHTML = this.menuIcon
        } else {
            this.menuTarget.classList.add('show')
            this.iconTarget.innerHTML = this.closeIcon
        }
    }

    get menuOpen() {
        return this.menuTarget.classList.contains('show')
    }
}