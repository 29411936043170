import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'addDocument', 'input' ];
  static values = { types: Array, previousDocuments: Array }

  initialize() {
    let submitController = this.submitFormsController

    this.inputTargets.forEach((input) => {
      if (input.attributes[0].value !== '') {
        document.querySelector(`label[for="${input.name}"]`).innerText = input.attributes[0].value
        input.classList.add('is-valid')
        input.required = false
        submitController.validateForm()
      }
    })
  }

  get submitFormsController() {
    let controllerElement = document.getElementById('submit-forms-controller')
    return this.application.getControllerForElementAndIdentifier(controllerElement, 'submit-forms')
  }

  updateLabel(e) {
    let input = e.target
    let fileName = input.value.split('fakepath\\').slice(-1)[0]

    document.querySelector(`label[for="${input.name}"]`).innerText = fileName
  }

  clearDocumentId(e) {
    let index = e.target.dataset.index
    let documentId = document.getElementById(`documents-${index}-id`)

    documentId.value = ''
  }

  remove(e) {
    let index = e.target.dataset.index
    let elementToRemove = document.getElementById(`field-${index}`)

    elementToRemove.remove()

    this.submitFormsController.addValid(e)
  }

  addDocument(e = null, doc = null) {
    let idx = this.inputTargets.length
    let newDiv = document.createElement("DIV")
    newDiv.className = 'form-row'
    newDiv.id = `field-${idx}`
    newDiv.innerHTML = `
        <div class="form-group col-md-6">
          <label for="document" class="form-label">Document</label>
          <div class="custom-file w-100">
            <input class="custom-file-input" 
                   type="file" 
                   accept="application/pdf" 
                   name="documents[${idx}][file]" 
                   id="documents[${idx}][file]" 
                   data-action="change->documents#updateLabel change->submit-forms#addValid" 
                   data-documents-target="input" 
                   data-submit-forms-target="required"
                   />
            <label class="custom-file-label" for="documents[${idx}][file]">
                ${doc ? doc.filename : 'Choose file...'}
            </label>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label for="documents[${idx}][documentType]" class="form-label">Document type</label>
          <select class="custom-select" name="documents[${idx}][document_type]" id="documents[${idx}][document_type]" data-submit-forms-target="required" data-action="change->submit-forms#addValid">
            <option value="" selected disabled="disabled" >Select a document type</option>
            <option value="Supp_Info">Supporting Information</option>
            <option value="Advisor_Letter">Advisor Letter</option>
            <option value="Final_Report">Final Report</option>
            <option value="Progress_Report">Progress Report</option>
            <option value="Final Review">Final Review</option> 
          </select>
        </div>
            
        <div class="form-group d-flex flex-column-reverse">
          <button type="button" class="btn btn-sm ncar-btn-secondary" data-action="documents#remove" data-index="${idx}">
            Remove
          </button>
        </div>`

    document.getElementById('documents-container').appendChild(newDiv)
  }
}
